import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyDt0j7Q_T4HhJItePU6Y5OgoMk229cICgE",
    authDomain: "kanoa-br.firebaseapp.com",
    projectId: "kanoa-br",
    storageBucket: "kanoa-br.appspot.com",
    messagingSenderId: "473137416279",
    appId: "1:473137416279:web:a2575ceda7ba0c4868fb12",
    measurementId: "G-15T5K6J6MR"
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;